*{
  box-sizing: boarder-box;
  margin: 0;
  padding: 0;
  font: 'Nucleo';
}

.App{
  width: 100%;
  min-height: 100vh;
  display: flex;
}

h1{
  color: #000;
  mix-blend-mode: difference;
  opacity: 80%;
  font-size: 45px;
  line-height: 1;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

h2{
  color: #fff;
  mix-blend-mode: difference;
  font-size: 25px;
  line-height: 1;
  position: absolute;
  bottom: 100px;
  opacity: 70%;
  left: 50%;
  transform: translateX(-50%);
}

.toggle{
  margin: auto;
}

.img-container{
  margin: auto;
  width: 100%;
  padding-top: 100px;
  padding-right: 50px;
  display: flex;
}

.container{
  margin: auto;
  width: 100%;
  padding-top: 100px;
  padding-left: 30%;
  max-width: 590px;
  display: flex;
  justify-content:  space-between;
  flex-wrap: wrap;
}

.container .card{
  width: 100%;
  max-width: 100px;
  margin: 10px 0;
  text-align: center;
}

.container .card p {
  margin: 12px 0;
  font-weight: 600;
  cursor: pointer;
}

.container .card .box {
  width: 100%;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


